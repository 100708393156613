import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { Link } from "react-router-dom";
import {
  getDatabase,
  ref,
  child,
  get,
  query,
  orderByChild,
  limitToLast,
  limitToFirst,
  startAfter,
  endAt,
  startAt,
  endBefore,
} from "firebase/database";
import ReactTooltip from "react-tooltip";

const firebaseConfig = {
  apiKey: "AIzaSyBV9fbXYxtWXQMcWBW1qjTjVq-wsEHg5W0",
  authDomain: "techchase-dfaf8.firebaseapp.com",
  databaseURL: "https://techchase-dfaf8-default-rtdb.firebaseio.com",
  projectId: "techchase-dfaf8",
  storageBucket: "techchase-dfaf8.appspot.com",
  messagingSenderId: "532600873024",
  appId: "1:532600873024:web:575e329bed1a157a8b3fa5",
};

firebase.initializeApp(firebaseConfig);

export default function PageHeader() {
  const [apiData, setApiData] = useState("");
  const dbRef = ref(getDatabase());

  const getData = async (companyName) => {
    var queryFinal;
    var jobListHTML = [];

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

    const randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)]

    queryFinal = query(
      child(dbRef, "/Companies/" + companyName + "/Jobs/"),
      orderByChild("title"),
      limitToFirst(1),
      startAfter(randomLetter),
      endBefore(randomLetter + "\uf8ff")
    );
    const response = await get(queryFinal)
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());

          var orderedValues = [];
          snapshot.forEach(function (child) {
            orderedValues.push(child.val());
          });
          return orderedValues;
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const data = await response;

    if (data !== undefined) {
      data.forEach((element, i) => {

        // pick confidence color
        var conf = element["confidence"];
        var confColor = {
          backgroundColor: "#ff0000",
        };
        if (conf === 3) {
          confColor = {
            backgroundColor: "#00ff00",
          };
        } else if (conf === 2) {
          confColor = {
            backgroundColor: "#ffff00",
          };
        }
  
        var currentSalary = "salary" in element ? element["salary"] : "?";
        const jobListWidth =  { 
          width: "26.666vw"
        }
  
        jobListHTML.push(
          <div
            id={companyName.toLowerCase() + "Job"}
            className="job"
            key={companyName + i}
            style = {jobListWidth}
          >
            <p id="jobTitle">{element["title"]}</p>
            <div className="jobDetailDiv">
              <div className="jobEstimatedSalaryBlock">
                <p className="jobDetail" data-tip data-for="salaryInfoPopup">
                  {"Estimated Salary: $" +
                    currentSalary
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <ReactTooltip id="salaryInfoPopup" place="top" effect="solid">
                  The estimated salary is based on public salary information on
                  the job posted
                </ReactTooltip>
                <div
                  className="jobDetail"
                  id="jobConfidenceGraphic"
                  style={confColor}
                  data-tip
                  data-for="confidenceInfoPopup"
                ></div>
              <ReactTooltip id="confidenceInfoPopup" place="top" effect="solid">
                Salary Estimate Confidence: 🟢 = High, 🟡 = Medium, 🔴 = Low
              </ReactTooltip>
              </div>
              <p className="jobDetail">
                {"Last Checked: " + element["dateScraped"]}
              </p>
              <p className="jobDetail">{element["location"]}</p>
              <a target="_blank" rel="noopener noreferrer" className="jobDetail" id="jobURL" href={element["URL"]}>
                READ MORE
              </a>
            </div>
          </div>
        );
      });
    }
    return jobListHTML;
  };

  function callAPI() {
    var jobListHTML = [];

    const getDatas = async () => {

      var companyNames = ["Google", "Amazon", "Apple", "Meta"]
      var chosenCompanies = []
      for (var i = 0; i < 3; i++) {
        const randomCompany = companyNames[Math.floor(Math.random() * companyNames.length)]
        
        var index = companyNames.indexOf(randomCompany);
        if (index !== -1) {
          companyNames.splice(index, 1);
        }

        chosenCompanies.push(randomCompany)
      }

      for (var j = 0; j < chosenCompanies.length; j++) {
        var jobListHTMLSection = await getData(chosenCompanies[j]);
        if (jobListHTMLSection.length > 0) {
          jobListHTML.push(jobListHTMLSection)
        }
        else {
          j -= 1
        }
      }
      setApiData(jobListHTML);
    };
    getDatas();
  }

  useEffect(() => {
    callAPI();
  }, []);

  return (
    <div>
      <div id="mainHeader">
        <h1 className="h1-main">TechChase</h1>
        <h3>Find your future</h3>
        <h6>
          Compiling information on high quality, high paying tech jobs free of
          charge
        </h6>

        <div id="featuredJobsContainer">
          <div id = "featuredJobsTitle">
            <p>Featured Jobs:</p>
          </div>
          <div id="featuredJobs">{apiData}</div>
        </div>
        <Link to="/jobCatalog">
          <button id = "featuredJobsSeeMore" className="standardButton">SEE MORE</button>
        </Link>
      </div>
    </div>
  );
}
