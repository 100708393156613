import React, { useState, useCallback, useEffect, useRef } from "react";
import { useKey } from "rooks";

import {
  getDatabase,
  ref,
  child,
  get,
  query,
  orderByChild,
  limitToLast,
  limitToFirst,
  startAfter,
} from "firebase/database";
import { initializeApp } from "firebase/app";
import ReactTooltip from "react-tooltip";
import MetaList from "./MetaList";
import GoogleList from "./GoogleList";
import AmazonList from "./AmazonList";
import AppleList from "./AppleList";

const firebaseConfig = {
  apiKey: "AIzaSyBV9fbXYxtWXQMcWBW1qjTjVq-wsEHg5W0",
  authDomain: "techchase-dfaf8.firebaseapp.com",
  databaseURL: "https://techchase-dfaf8-default-rtdb.firebaseio.com",
  projectId: "techchase-dfaf8",
  storageBucket: "techchase-dfaf8.appspot.com",
  messagingSenderId: "532600873024",
  appId: "1:532600873024:web:575e329bed1a157a8b3fa5",
};

initializeApp(firebaseConfig);

export default function JobCatalog() {

  const [allJobs, setAllJobs] = useState("");

  const metaRef = useRef("");
  const amazonRef = useRef("");
  const googleRef = useRef("");
  const appleRef = useRef("");


  const [isCheckedAmazon, setIsCheckedAmazon] = useState(true);
  const [isCheckedApple, setIsCheckedApple] = useState(true);
  const [isCheckedGoogle, setIsCheckedGoogle] = useState(true);
  const [isCheckedMeta, setIsCheckedMeta] = useState(true);

  const [isSearchBoxHidden, setIsSearchBoxHidden] = useState(false);

  const [isOverlayHidden, setIsOverlayHidden] = useState(true)

  useEffect(() => {
    const d = new Date()
    if (d.getUTCHours() === 7) {
      setIsOverlayHidden(false)
    }
    else {
      setIsOverlayHidden(true)
      searchClicked();
    }
  }, []);

  useKey(["Enter"], searchClicked);

  function dropDownChanged() {
    document.getElementById("searchTextbox").value = ""
    setIsSearchBoxHidden(!isSearchBoxHidden)
  }

  const handleOnChangeGoogle = () => {
    setIsCheckedGoogle(!isCheckedGoogle);
  };
  const handleOnChangeAmazon = () => {
    setIsCheckedAmazon(!isCheckedAmazon);
  };
  const handleOnChangeMeta = () => {
    setIsCheckedMeta(!isCheckedMeta);
  };
  const handleOnChangeApple = () => {
    setIsCheckedApple(!isCheckedApple);
  };

  function searchClicked() {
    var finalHTML = []

    var dropDownElement = document.getElementById("filterDropDown");
    var dropDownValue = dropDownElement.value;

    var searchTextboxElement = document.getElementById("searchTextbox");
    var searchTextboxValue = searchTextboxElement.value;


    var jobListWidth = "80vw"
    const numberChecked = [isCheckedGoogle, isCheckedMeta, isCheckedAmazon, isCheckedApple].filter(Boolean).length
    if (numberChecked === 4) {
      jobListWidth = "20vw"
    }
    else if (numberChecked === 3) {
      jobListWidth = "26.666vw"
    }
    else if (numberChecked === 2) {
      jobListWidth = "40vw"
    }
    

    if (isCheckedGoogle) {
      finalHTML.push(<GoogleList jobListWidth = {jobListWidth} ref={googleRef}></GoogleList>)
    }
    if (isCheckedMeta) {
      finalHTML.push(<MetaList jobListWidth = {jobListWidth} ref={metaRef}></MetaList>)
    }
    if (isCheckedAmazon) {
      finalHTML.push(<AmazonList jobListWidth = {jobListWidth} ref={amazonRef}></AmazonList>)
    }
    if (isCheckedApple) {
      finalHTML.push(<AppleList jobListWidth = {jobListWidth} ref={appleRef}></AppleList>)
    }
    
    setAllJobs(
      <div id = "jobListTotal">
        {finalHTML}
      </div>
    )


    try {
      metaRef.current.callAPIFromParent(dropDownValue, searchTextboxValue);
    } 
    catch {
      console.log("meta list doesnt exist yet")  
    }
    try {
      googleRef.current.callAPIFromParent(dropDownValue, searchTextboxValue);
    } 
    catch {
      console.log("google list doesnt exist yet")
    }
    try {
      amazonRef.current.callAPIFromParent(dropDownValue, searchTextboxValue);
    } 
    catch {
      console.log("amazon list doesnt exist yet")
    }
    try {
      appleRef.current.callAPIFromParent(dropDownValue, searchTextboxValue);
    } 
    catch {
      console.log("apple list doesnt exist yet")
    }
  }

  return (
    <div id="jobDiv">
      <div hidden = {isOverlayHidden} id = "updateOverlay">
      <h1 id = "updateOverlayText">
        We're sorry but the job information is currently being updated. This regularly takes place from 3am-4am EST (7am-8am UTC). Please check back after the updating has finished. We appreciate your patience.
      </h1>
      </div>
      <div id="jobSearchOptions">
        <div id="jobSearchBlockFirst" className="jobSearchBlock">
          <div className="jobSearchOptionsRow">
            <input
              type="checkbox"
              className="searchCheckbox"
              name="searchCheckboxGoogle"
              value="Google"
              checked={isCheckedGoogle}
              onChange={handleOnChangeGoogle}
            />
            <label className="searchCheckboxLabel" htmlFor="searchCheckboxGoogle">
              Google
            </label>
            <input
              type="checkbox"
              className="searchCheckbox"
              name="searchCheckboxApple"
              value="Apple"
              checked={isCheckedApple}
              onChange={handleOnChangeApple}
            />
            <label className="searchCheckboxLabel" htmlFor="searchCheckboxApple">
              Apple
            </label>
          </div>

          <div className="jobSearchOptionsRow">
            <input
              type="checkbox"
              className="searchCheckbox"
              name="searchCheckboxAmazon"
              value="Amazon"
              checked={isCheckedAmazon}
              onChange={handleOnChangeAmazon}
            />
            <label className="searchCheckboxLabel" htmlFor="searchCheckboxAmazon">
              Amazon
            </label>
            <input
              type="checkbox"
              className="searchCheckbox"
              name="searchCheckboxMeta"
              value="Meta"
              checked={isCheckedMeta}
              onChange={handleOnChangeMeta}
            />
            <label className="searchCheckboxLabel" htmlFor="searchCheckboxMeta">
              Meta
            </label>
          </div>
        </div>

        <div className="jobSearchBlock">
          <select id="filterDropDown" onChange={() => dropDownChanged()}>
            <option value="JOB TITLE">JOB TITLE</option>
            <option value="SALARY">SALARY</option>
          </select>
        </div>

        <div className="jobSearchBlock">
          <div hidden={isSearchBoxHidden}>
            <label
              htmlFor="fname"
              id="searchTextboxLabel"    
              className="genericSearchLabel"          
            >
              Title Starts With:
            </label>
          </div>
          <div hidden={!isSearchBoxHidden}>
            <label
              htmlFor="fname"
              id="salaryTextboxLabel"  
              className="genericSearchLabel"            
            >
              Minimum Salary:
            </label>
          </div>
          <div>
            <input
              className="genericSearchTextbox"
              id="searchTextbox"
              type="text"
              name="fname"
              defaultValue = "software"
            ></input>
          </div>
        </div>

        <div className="jobSearchBlock">
          <button className = "standardButton" onClick={() => searchClicked()}>SEARCH</button>
        </div>
      </div>

      <div>{allJobs}</div>
    </div>
  
  );
}
