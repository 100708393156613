import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav>
      <ul>
        <div id = "tabbar">
        <Link to="/">
          <img alt = "home" id = "logoTabbar" src={require("./logo.png")}></img>
        </Link>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/jobCatalog">Job Catalog</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
        </div>
      </ul>
    </nav>
  );
}

export default Navbar;
