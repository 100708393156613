import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { Link } from "react-router-dom";

export default function About() {

  return (
    <div>
      <div id="mainHeader">
        <h1 id="h1-main" style={{ margin: "100px" }}>
          ABOUT
        </h1>
        <p id="aboutPageText">
          TechChase is a website designed to help you find tech jobs without the
          hassle of searching multiple websites and guessing at your salary. We
          search daily for the latest jobs from the biggest tech companies and
          then scour the web for public salary information to determine an
          estimated salary for all software and related career fields.
          <br />
          <br />
          This website is completely free to use and requires no membership of any
          kind. Feel free to take a look around our job board and explore the latest tech jobs.
          <br />
          <br />
          TechChase is not affiliated with any companies listed on this website.
          All data displayed is taken from publicly available sources.
        </p>
      </div>
    </div>
  );
}
