import React, { useState } from "react";

export default function FAQ() {
  const [divClassName, setDivClassName] = useState("faqAnswerClose");
  const [divClassName2, setDivClassName2] = useState("faqAnswerClose");
  const [divClassName3, setDivClassName3] = useState("faqAnswerClose");

  function toggleDiv(row) {
    if (row === 1) {
        if (divClassName === "faqAnswer") {
            setDivClassName("faqAnswerClose")
        }
        else {
            setDivClassName("faqAnswer")
        }
    }
    else if (row === 2){
        if (divClassName2 === "faqAnswer") {
            setDivClassName2("faqAnswerClose")
        }
        else {
            setDivClassName2("faqAnswer")
        }
    }
    else if (row === 3){
        if (divClassName3 === "faqAnswer") {
            setDivClassName3("faqAnswerClose")
        }
        else {
            setDivClassName3("faqAnswer")
        }
    }
  }

  return (
    <div>
      <div id="mainHeader">
        <h1 id="h1-main" style={{ margin: "100px" }}>
          FAQs
        </h1>
        <div className = "faqQuestion" onClick={() => toggleDiv(1)}>
            How often are jobs updated?
        </div>
        <div className = {divClassName}>
            All jobs and salary estimates are updated at 3am - 4am EST. If you are checking the website during these times jobs may be unavailable or inaccurate.
        </div>
        <div className = "faqQuestion" onClick={() => toggleDiv(2)}>
            Are you affiliated with the companies listed? 
        </div>
        <div className = {divClassName2}>
            No, we are not affiliated with any of the companies on this site. All data is compiled from publicly available information.
        </div>
        <div className = "faqQuestion" onClick={() => toggleDiv(3)}>
            Are there any costs associated with using this site?
        </div>
        <div className = {divClassName3}>
            No, everything on this site is free and requires no sign-up or membership.
        </div>
      </div>
    </div>
  );
}
