import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import './App.css';
import Layout from "./Layout";
import PageHeader from './PageHeader';
import JobCatalog from './JobCatalog';
import About from './About';
import FAQ from './FAQ';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PageHeader />} />
          <Route path="/jobCatalog" element={<JobCatalog />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="*" element={<JobCatalog />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
ReactDOM.render(<App />, document.getElementById("root"));
