import React, { useState, useCallback, useEffect, forwardRef, useImperativeHandle } from "react";

import {
  getDatabase,
  ref,
  child,
  get,
  query,
  orderByChild,
  limitToLast,
  limitToFirst,
  startAfter,
  endAt,
  startAt,
  endBefore,
} from "firebase/database";
import { initializeApp } from "firebase/app";
import ReactTooltip from "react-tooltip";

const firebaseConfig = {
  apiKey: "AIzaSyBV9fbXYxtWXQMcWBW1qjTjVq-wsEHg5W0",
  authDomain: "techchase-dfaf8.firebaseapp.com",
  databaseURL: "https://techchase-dfaf8-default-rtdb.firebaseio.com",
  projectId: "techchase-dfaf8",
  storageBucket: "techchase-dfaf8.appspot.com",
  messagingSenderId: "532600873024",
  appId: "1:532600873024:web:575e329bed1a157a8b3fa5",
};

initializeApp(firebaseConfig);

const MetaList = forwardRef((props, curRef) => {
    
  const [apiData, setApiData] = useState("");
  const dbRef = ref(getDatabase());
  const [lastSearch, setLastSearch] = useState([]);
  const [lastTitle, setLastTitle] = useState([]);
  const [lastSalary, setLastSalary] = useState([]);
  const [lastAPIData, setLastAPIData] = useState([]);

  useImperativeHandle(curRef, () => ({
    callAPIFromParent(dropDownVal, searchTextboxVal) {
      setLastTitle([])
      setLastSalary([])
      callAPI(dropDownVal, searchTextboxVal);
    },
  }));

  const nextPage = async () => {

    const companyName = lastSearch[0];
    const lastDropDownOption = lastSearch[1];
    const lastKeyword = lastSearch[2];

    var jobListHTML = [];

    var queryFinal;
    
    if (lastDropDownOption === "SALARY") {
      if (lastKeyword !== undefined && lastKeyword !== "") {
        queryFinal = query(
          child(dbRef, "/Companies/" + companyName + "/Jobs/"),
          orderByChild("salary"),
          limitToLast(10),
          startAfter(parseInt(lastKeyword)),
          endAt(lastSalary[lastSalary.length - 1])
        );
      }
      else {
        queryFinal = query(
          child(dbRef, "/Companies/" + companyName + "/Jobs/"),
          orderByChild("salary"),
          limitToLast(10),
          endAt(lastSalary[lastSalary.length - 1])
        );
      }
    } 
    else {
      if (lastKeyword !== undefined && lastKeyword !== "") {
        const searchTextboxValCapped = lastKeyword.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        queryFinal = query(
          child(dbRef, "/Companies/" + companyName + "/Jobs/"),
          orderByChild("title"),
          limitToFirst(10),
          startAfter(lastTitle[lastTitle.length - 1]),
          endBefore(searchTextboxValCapped + "\uf8ff")
        )
      }
      else {
        queryFinal = query(
          child(dbRef, "/Companies/" + companyName + "/Jobs/"),
          orderByChild("title"),
          limitToFirst(10),
          startAfter(lastTitle[lastTitle.length - 1])
        )
      }
    }

    const response = await get(queryFinal)
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());

          var orderedValues = [];
          snapshot.forEach(function (child) {
            orderedValues.push(child.val());
          });
          if (lastDropDownOption === "SALARY") {
            return orderedValues.reverse();
          }
          return orderedValues;
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const data = await response;

    

    var lastTitleCur = ""
    var lastSalaryCur = 0
    data.forEach((element) => {
      lastTitleCur = element["title"];
      lastSalaryCur = element["salary"] - 1

      // pick confidence color
      var conf = element["confidence"];
      var confColor = {
        backgroundColor: "#ff0000",
      };
      if (conf === 3) {
        confColor = {
          backgroundColor: "#00ff00",
        };
      } else if (conf === 2) {
        confColor = {
          backgroundColor: "#ffff00",
        };
      }

      var currentSalary = "salary" in element ? element["salary"] : "?";

      jobListHTML.push(
        <div
          id={companyName.toLowerCase() + "Job"}
          className="job"
          key={companyName + data.indexOf(element)}
        >
          <p id="jobTitle">{element["title"]}</p>
          <div className="jobDetailDiv">
            <div className="jobEstimatedSalaryBlock">
              <p className="jobDetail" data-tip data-for="salaryInfoPopup">
                {"Estimated Salary: $" +
                  currentSalary
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              <ReactTooltip id="salaryInfoPopup" place="top" effect="solid">
                The estimated salary is based on public salary information on
                the job posted
              </ReactTooltip>
              <div
                className="jobDetail"
                id="jobConfidenceGraphic"
                style={confColor}
                data-tip
                data-for="confidenceInfoPopup"
              ></div>
              <ReactTooltip id="confidenceInfoPopup" place="top" effect="solid">
                Salary Estimate Confidence: 🟢 = High, 🟡 = Medium, 🔴 = Low
              </ReactTooltip>
            </div>
            <p className="jobDetail">
              {"Last Checked: " + element["dateScraped"]}
            </p>
            <p className="jobDetail">{element["location"]}</p>
            <a target="_blank" rel="noopener noreferrer" className="jobDetail" id="jobURL" href={element["URL"]}>
              READ MORE
            </a>
          </div>
        </div>
      );
    });
    setLastTitle((lastTitle) => [...lastTitle, lastTitleCur]);
    setLastSalary((lastSalary) => [...lastSalary, lastSalaryCur]);
    
    return jobListHTML;
  };

  const getData = async (dropDownVal, companyName, searchTextboxVal) => {
    var queryFinal;
    var jobListHTML = [];

    var sortDropDownCurrentVal = dropDownVal;
    if (searchTextboxVal !== undefined && searchTextboxVal !== "") {
      if (sortDropDownCurrentVal === "SALARY") {
        queryFinal = query(
          child(dbRef, "/Companies/" + companyName + "/Jobs/"),
          orderByChild("salary"),
          limitToLast(10),
          startAfter(parseInt(searchTextboxVal))
        );
      } else {
        const searchTextboxValCapped = searchTextboxVal
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");

        queryFinal = query(
          child(dbRef, "/Companies/" + companyName + "/Jobs/"),
          orderByChild("title"),
          limitToFirst(10),
          startAt(searchTextboxValCapped),
          endBefore(searchTextboxValCapped + "\uf8ff")
        );
      }
    } else {
      if (sortDropDownCurrentVal === "SALARY") {
        queryFinal = query(
          child(dbRef, "/Companies/" + companyName + "/Jobs/"),
          orderByChild("salary"),
          limitToLast(10)
        );
      } else {
        queryFinal = query(
          child(dbRef, "/Companies/" + companyName + "/Jobs/"),
          orderByChild("title"),
          limitToFirst(10)
        );
      }
    }

    

    setLastSearch([companyName, sortDropDownCurrentVal, searchTextboxVal]);

    const response = await get(queryFinal)
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());

          var orderedValues = [];
          snapshot.forEach(function (child) {
            orderedValues.push(child.val());
          });
          if (sortDropDownCurrentVal === "SALARY") {
            return orderedValues.reverse();
          }
          return orderedValues;
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const data = await response;

    if (data === undefined) {
      return
    }

    var lastTitleCur = ""
    var lastSalaryCur = 0
    data.forEach((element, i) => {
      lastTitleCur = element["title"];
      lastSalaryCur = element["salary"];
      

      // pick confidence color
      var conf = element["confidence"];
      var confColor = {
        backgroundColor: "#ff0000",
      };
      if (conf === 3) {
        confColor = {
          backgroundColor: "#00ff00",
        };
      } else if (conf === 2) {
        confColor = {
          backgroundColor: "#ffff00",
        };
      }

      var currentSalary = "salary" in element ? element["salary"] : "?";

      jobListHTML.push(
        <div
          id={companyName.toLowerCase() + "Job"}
          className="job"
          key={companyName + i}
        >
          <p id="jobTitle">{element["title"]}</p>
          <div className="jobDetailDiv">
            <div className="jobEstimatedSalaryBlock">
              <p className="jobDetail" data-tip data-for="salaryInfoPopup">
                {"Estimated Salary: $" +
                  currentSalary
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              <ReactTooltip id="salaryInfoPopup" place="top" effect="solid">
                The estimated salary is based on public salary information on
                the job posted
              </ReactTooltip>
              <div
                className="jobDetail"
                id="jobConfidenceGraphic"
                style={confColor}
                data-tip
                data-for="confidenceInfoPopup"
              ></div>
              <ReactTooltip id="confidenceInfoPopup" place="top" effect="solid">
                Salary Estimate Confidence: 🟢 = High, 🟡 = Medium, 🔴 = Low
              </ReactTooltip>
            </div>
            <p className="jobDetail">
              {"Last Checked: " + element["dateScraped"]}
            </p>
            <p className="jobDetail">{element["location"]}</p>
            <a target="_blank" rel="noopener noreferrer" className="jobDetail" id="jobURL" href={element["URL"]}>
              READ MORE
            </a>
          </div>
        </div>
      );
    });

    setLastTitle((lastTitle) => [...lastTitle, lastTitleCur]);
    setLastSalary((lastSalary) => [...lastSalary, lastSalaryCur]);
    
    return jobListHTML;
  };

  function callAPI(dropDownVal, searchTextboxVal) {
    var jobListHTML = [];

    const getDatas = async (dropDownVal, searchTextboxVal) => {

      jobListHTML = await getData(dropDownVal, "Meta", searchTextboxVal);

      setApiData(<div>{jobListHTML}</div>);
    };
    getDatas(dropDownVal, searchTextboxVal);
  }

  useEffect(() => {
    callAPI("JOB TITLE", "software");
  }, []);


  function nextButtonClicked() {
    var jobListHTMLM = []
    const getDatas = async () => {
      jobListHTMLM = await nextPage();
      setLastAPIData(lastAPIData => [...lastAPIData, apiData]);
      setApiData(<div>{jobListHTMLM}</div>);
    };
    getDatas();
  }

  function prevButtonClicked() {
    if (lastAPIData.length > 0) {
      if (lastSearch[1] === "SALARY") {
        const curLastAPIData = lastAPIData.pop();
        setApiData(<div>{curLastAPIData}</div>);
        lastSalary.pop()
      }
      else {
        const curLastAPIData = lastAPIData.pop();
        setApiData(<div>{curLastAPIData}</div>);
        lastTitle.pop()
      }
    }
  }

  var jobListStyle = {
    width: props.jobListWidth
  }


  return (
    <div>
      <div className="jobList" style = {jobListStyle}>
        <button className = "nextPrevButton" onClick={() => prevButtonClicked()}>PREVIOUS</button>
        <button className = "nextPrevButton" onClick={() => nextButtonClicked()}>NEXT</button>
        {apiData}
        <button className = "nextPrevButton" onClick={() => prevButtonClicked()}>PREVIOUS</button>
        <button className = "nextPrevButton" onClick={() => nextButtonClicked()}>NEXT</button>
      </div>
    </div>
  );
})

export default MetaList